function Validator(value) {
  let err = {};
  const email_regex = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim;
  // const password_regex =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_])[A-Za-z\d$@$!%*?&_]{minlength,maxlength}$/;

  if (value.username === "") {
    err.username = "Data tidak bleh kosong!";
  } else {
    err.username = "";
  }

  if (value.email === "") {
    err.email = "Data tidak boleh kosong!";
  } else if (!email_regex.test(value.email)) {
    err.email = "Format Data kurang tepat!";
  } else {
    err.email = "";
  }
  if (value.password === "") {
    err.password = "Data tidak boleh kosong!";
  } else {
    err.password = "";
  }
  return err;
}

export default Validator;
