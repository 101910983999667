import TabsPopuler from "../components/tabs/TabsPopuler";
import SwiperPopuler from "../components/swiper/SwiperPopuler";
import CardTitle from "../components/card/CardTitle";
import animatedPages from "../animatedPages";
import SwiperReverb from "../components/swiper/SwiperReverb";
import SwiperSlow from "../components/swiper/SwiperSlow";
import Banner from "../components/alert/banner/Banner";
import React, { useState } from "react";
import SwiperAI from "../components/swiper/SwiperAI";

export default function Home() {
  const [isVisible, setVisible] = useState(true);

  setTimeout(() => {
    document.getElementById("banner").style.opacity = 0;
    document.getElementById("banner").style.visibility = "hidden";
    document.getElementById("banner").style.transition = "all 0.9s ease";
  }, 8000);

  return (
    <>
      <animatedPages>
        {isVisible && <Banner />}
        <div className="w-full">
          <div className="w-100 h-10">
            <label className="text-sm text-white">Home</label>
          </div>
          <CardTitle />
          <div className="mb-8">
            <h1 className="text-xl text-[#25F000]">About Scorpion Music</h1>
            <p className="text-sm" style={{ textIndent: "25px" }}>
              Scorpion Music is website music reverb, slow, and speadbeat. you
              can access music with Free. Website is pre build and features
              minim
            </p>
          </div>

          <div>
            <SwiperPopuler />
          </div>

          <div>
            <SwiperReverb />
          </div>

          <div>
            <SwiperSlow />
          </div>

          <div>
            <SwiperAI />
          </div>
        </div>
      </animatedPages>
    </>
  );
}
