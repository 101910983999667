const NotFound = () => {
  return (
    <>
      <div className="w-full h-full flex justify-center mt-10 text-center">
        <p className="text-gray-300 text-sm">
          Sorry, URL Not Found. Please Check URL Again.
        </p>
      </div>
      <div className="absolute bottom-5 right-5 text-gray-600 text-sm">
        CopyRight &copy; FauzanAPP. Developer ScorpionMusic
      </div>
    </>
  );
};

export default NotFound;
