import "./BottomBar.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React, { useState } from "react";
import AlertListen from "../alert/AlertListen";

const BottomBar = ({ musicImages, musicName, musicAudio, onClose }) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <>
      <div>
        <div
          class="bottombar fixed bottom-0 left-0 z-50  w-full h-19  px-8 opacity-90 backdrop-blur-md md:grid-cols-3 dark:bg-gray-700 dark:border-gray-600 transition-all"
          style={{
            background: "#0b0017",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div class="items-center justify-center hidden me-auto md:flex">
            <img
              className="h-8 me-3 rounded-lg"
              src={musicImages}
              alt="Video preview"
            />
            <span
              class="text-sm text-white dark:text-gray-400"
              style={{
                overflowX: "scroll",
                whiteSpace: "nowrap",
                width: "200px",
              }}
            >
              {musicName}
            </span>
          </div>
          <AudioPlayer
            autoPlay
            src={musicAudio}
            layout="horizontal"
            onListen={() => {
              setVisible(true);
            }}
          />
          <button onClick={onClose} className="bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default BottomBar;
