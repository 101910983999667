// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import database from "../../databases/databaseAI.json";
import React, { useState } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./SwiperPopuler.css";

// import required modules
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import CardMusic2 from "../card/CardMusic2";
import BottomBar from "../bottombar/BottomBar";

const SwiperAI = () => {
  const [isVisible, setVisible] = useState(false);
  const [isName, setName] = useState();
  const [isImages, setImages] = useState();
  const [isAudio, setAudio] = useState();

  // const onClickEvent = () => {

  // };

  const onCloseEvent = () => {
    setVisible(false);
  };

  return (
    <>
      <div>
        {isVisible && (
          <BottomBar
            musicName={isName}
            musicAudio={isAudio}
            musicImages={isImages}
            onClose={onCloseEvent}
          />
        )}
      </div>
      <div className="mb-6">
        <h1 className="text-md text-white">AI Cover Music</h1>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        freeMode={true}
        modules={[FreeMode, Navigation, Pagination]}
        className="swiper-populer z-20"
      >
        {database.map(
          (
            {
              musicName,
              musicBackground,
              musicSubtitle,
              musicImageLink,
              router,
              musicLink,
            },
            index
          ) => {
            return (
              <SwiperSlide key={index}>
                <CardMusic2
                  musicName={musicName}
                  musicDesc={musicSubtitle}
                  musicImages={musicImageLink}
                  onClick={() => {
                    setVisible(true);
                    setImages(musicImageLink);
                    setName(musicName);
                    setAudio(musicLink);
                  }}
                />
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </>
  );
};

export default SwiperAI;
