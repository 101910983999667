import animatedPages from "../animatedPages";

const Update = () => {
  return (
    <>
      <animatedPages>
        <div>
          <div className="w-100 h-10">
            <label className="text-sm text-white">Update</label>
          </div>
          <h1 className="text-md text-[#25F000]">ScorpionMusic</h1>
          <p>
            Scorpion Music is website music reverb, slow, and speadbeat. you can
            access music with Free. Website is pre build and features minim
          </p>
          <div className="mt-4 mb-4">
            <p className="text-white">Scorpion Update Logs</p>
          </div>
          <div className="w-full" id="log">
            <div
              className="p-5 relative bg-[#0a0018] shadow-2xl"
              style={{
                width: "100%",
                borderRadius: "19px",
              }}
            >
              <div className="flex gap-3">
                <label classaName="text-sm text-white">Scorpion V 0.0.1</label>
                <span class="inline-block whitespace-nowrap rounded-[0.27rem] bg-[#020018] shadow-md px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700">
                  Update
                </span>
              </div>
              <div className="flex flex-col mt-2 text-sm">
                <span>[+] Pembukaan Website ScorpionMusic</span>
                <span>[+] Penambahan Music 12+</span>
                <span className="absolute text-sm bottom-3 right-3">
                  Updated 12 Januari 2023
                </span>
              </div>
            </div>
          </div>
        </div>
      </animatedPages>
    </>
  );
};

export default Update;
