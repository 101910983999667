import Sidebar from "../components/sidebar/Sidebar";
import Navbars from "../components/navigation/Navbars";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "grid",
          gridTemplateColumns: "0.33fr 1fr",
          gridTemplateRows: "0.1fr 1fr",
          // gridColumnGap: "12px",
          // gridRowGap: "12px",
        }}
      >
        <div style={{ gridArea: "1 / 1 / 2 / 3" }}>
          <Navbars />
        </div>
        <div
          style={{
            gridArea: "2 / 1 / 3 / 2",
            overflow: "auto",
          }}
        >
          <Sidebar />
        </div>
        <div
          style={{
            gridArea: "2 / 2 / 3 / 3",
            padding: "2rem 4rem",
            overflow: "auto",
          }}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
