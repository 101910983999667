const Navbars = () => {
  return (
    <>
      <div
        class="fixed top-0 left-0 w-full z-50"
        style={{
          backgroundColor: "#0a0015",
          boxShadow: "2px 2px 19px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div class="mx-auto max-w-7xl px-6 sm:px-6 lg:px-8 ">
          <div class="relative flex h-16 justify-between">
            <div class="flex flex-1 items-stretch justify-start">
              <a class="flex flex-shrink-0 items-center" href="#">
                <span className="text-white">ScorpionMusic</span>
              </a>
            </div>
            <div class="flex-shrink-0 flex px-2 py-3 items-center space-x-8">
              <a
                class="text-white hover:text-[#00E307] transition-all text-sm font-medium"
                href="/login"
              >
                Login
              </a>
              <a
                class="bg-gray-900 transition-all hover:bg-black inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm "
                style={{ color: "#00E307 " }}
                href="/signup"
              >
                Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbars;
