import Gambar from "../../images/bg-sss.gif";

const CardTitle = () => {
  return (
    <>
      <div className="py-6 w-full aspect-video">
        <div
          className="relative flex w-full h-full z-[0] rounded-lg overflow-hidden bg-black"
          style={{ boxShadow: "3px 3px 20px rgba(0, 0, 0, 0.6)" }}
        >
          <div
            className="absolute opacity-[0.3] z-[0] rounded-lg aspect-video w-full top-0 left-0 bg-cover"
            style={{
              backgroundImage: `url('${Gambar}')`,
            }}
          ></div>
          <div className="w-full p-4 flex flex-col justify-between ">
            <div>
              <h1 className="text-white font-bold text-2xl">Royality</h1>
              <p className="mt-2 text-[rgba(255, 255, 255, 0.4)] text-sm">
                Sebuah Music Populer dari tahun ke tahun yang biasa di jadikan
                music bakcground oleh Gamers.
              </p>
              <div className="flex item-center mt-2">
                <svg
                  className="w-5 h-5 fill-current text-orange-500"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                </svg>
                <svg
                  className="w-5 h-5 fill-current text-orange-500"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                </svg>
                <svg
                  className="w-5 h-5 fill-current text-orange-500"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                </svg>
                <svg
                  className="w-5 h-5 fill-current text-orange-500"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                </svg>
                <svg
                  className="w-5 h-5 fill-current text-orange-500"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                </svg>
              </div>
            </div>
            <div className="flex item-center justify-between mt-3">
              <h1 className="text-white text-sm">Free Listen Music</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardTitle;
