import "./App.css";
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import React from "react";
// import MusicPlayer from "./pages/music/MusicPlayer";
import Layout from "./layout/Layout";
import MusicPlayer from "./pages/music/MusicPlayer";
import NotFound from "./pages/NotFound";
import Update from "./pages/Update";
import Login from "./components/login/Login";
import Signup from "./components/login/Signup";
import Pricing from "./pages/Pricing";
import animatedPages from "./animatedPages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="update" element={<Update />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/pricing" element={<Pricing />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
