import database from "../../databases/database.json";
import "./CardMusic2.css";

const CardMusic2 = ({
  musicImages,
  musicName,
  musicDesc,
  router,
  onClick,
  onClose,
}) => {
  return (
    <>
      <div class="bg-[#0a0015] shadow-2xl rounded p-2 w-[250px] h-[280px]">
        <div class="group relative">
          <img
            alt={musicName}
            class="block h-[190px] w-full rounded object-cover"
            src={musicImages}
            style={{ boxShadow: "2px 2px 13px rgba(0, 0, 0, 0.9)" }}
          />
          <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 duration-700 transition justify-evenly">
            <button
              onClick={onClick}
              class="hover:scale-110 text-white outline-none opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-play-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
              </svg>
            </button>
          </div>
        </div>
        <div class="p-2">
          <div>
            <h3
              class=" text-white py-1 justify-center"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {musicName}
            </h3>
          </div>
          <p class="text-gray-400 mt-[-3px] text-sm">{musicDesc}</p>
        </div>
      </div>
    </>
  );
};

export default CardMusic2;
