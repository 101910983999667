import Logo from "../../images/scorpion.png";
// import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <aside
        class="flex flex-col rounded w-full h-100 px-5 py-8 overflow-y-scroll dark:bg-gray-900 dark:border-gray-700"
        style={{
          backgroundColor: "#0a0015",
          backdropFilter: "blur(7px)",
        }}
      >
        <Link to="/">
          <img class="w-auto h-21" src={Logo} alt="Scorpion" />
        </Link>

        <div class="flex flex-col justify-between flex-1 mt-6">
          <nav class="-mx-3 space-y-3 ">
            <Link
              class="flex items-center px-3 py-2 text-white transition-all transform rounded-lg dark:text-gray-300 hover:translate-x-[10px] hover:bg-[#25F000] hover:text-black"
              to="/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>

              <span class="mx-2 text-sm font-medium">Home</span>
            </Link>

            <Link
              class="flex items-center px-3 py-2 text-white transition-all transform rounded-lg dark:text-gray-300 hover:translate-x-[10px] hover:bg-[#25F000] hover:text-black"
              to="/pricing"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="2" y="4" width="20" height="16" rx="2" />
                <path d="M7 15h0M2 9.5h20" />
              </svg>

              <span class="mx-2 text-sm font-medium">Pricing</span>
            </Link>

            <Link
              class="flex items-center px-3 py-2 text-white transition-all transform rounded-lg dark:text-gray-300 hover:translate-x-[10px] hover:bg-[#25F000] hover:text-black"
              to="/update"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                />
              </svg>

              <span class="mx-2 text-sm font-medium">Update</span>
            </Link>

            <Link
              class="flex items-center px-3 py-2 text-white transition-all transform rounded-lg dark:text-gray-300 hover:translate-x-[10px] hover:bg-[#25F000] hover:text-black"
              to="https://saweria.co/RoyalReverb"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="12" y1="1" x2="12" y2="23"></line>
                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>

              <span class="mx-2 text-sm font-medium">Donate</span>
            </Link>

            <Link
              class="flex items-center px-3 py-2 text-white transition-all transform rounded-lg dark:text-gray-300 hover:translate-x-[10px] hover:bg-[#25F000] hover:text-black"
              to="https://wa.me/+6283106414327"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>

              <span class="mx-2 text-sm font-medium">Contact Us</span>
            </Link>
          </nav>

          <div>
            <div class="flex items-center justify-between mt-3">
              <h2 class="text-base font-semibold text-[#25F000] dark:text-white">
                Social Media
              </h2>
            </div>

            <nav class="mt-4 -mx-3 space-y-3 ">
              <button
                onClick={() =>
                  (window.location.to =
                    "https://www.youtube.com/channel/UC6hzBajSq__RODwyC7dQAxQ")
                }
                class="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-white"
              >
                <div class="flex items-center gap-x-2 ">
                  <span class="w-2 h-2 bg-[#25F000] rounded-full"></span>
                  <span>Youtube ScorpionMusic</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#25F000"
                  class="w-4 h-4 rtl:rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>

              <button class="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-white">
                <div class="flex items-center gap-x-2 ">
                  <span class="w-2 h-2 bg-[#25F000] rounded-full"></span>
                  <span>Instagram ScorpionMusic</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#25F000"
                  class="w-4 h-4 rtl:rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>

              <button class="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-white">
                <div class="flex items-center gap-x-2 ">
                  <span class="w-2 h-2 bg-[#25F000] rounded-full"></span>
                  <span>Spotify ScorpionMusic</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#25F000"
                  class="w-4 h-4 rtl:rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>

              <button class="flex items-center justify-between w-full px-3 py-2 text-xs font-medium text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-white">
                <div class="flex items-center gap-x-2 ">
                  <span class="w-2 h-2 bg-[#25F000] rounded-full"></span>
                  <span>TikTok ScorpionMusic</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#25F000"
                  class="w-4 h-4 rtl:rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
