import React, { useState } from "react";
import Validator from "../../validator/loginValidator";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
  const [values, setValue] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({});

  const handleInput = (ev) => {
    setValue((prev) => ({ ...prev, [ev.target.name]: [ev.target.value] }));
  };
  const navigate = useNavigate();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setError(Validator(values));
    if (error.email === "" && error.password === "" && error.username === "") {
      axios
        .post("http://localhost:8000/login", values)
        .then((res) => {
          console.log(res);
          navigate("/");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="mx-auto px-4 py-16 sm:px-6 lg:px-8 overflow-hidden overflow-y-auto bg-[#0a0018]">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-[#26f000] sm:text-3xl">
            Get started today
          </h1>

          <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
            Join to ScorpionMusic to get new Experience
          </p>

          <form
            action=""
            method="post"
            onSubmit={handleSubmit}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <p className="text-center text-white text-lg font-medium">
              Sign in to your account
            </p>

            <div>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  className="w-full rounded-lg bg-transparent border-[#25F000] p-4 text-white space-x-4 pe-12 text-sm shadow-sm"
                  placeholder="Enter email"
                  onChange={handleInput}
                />

                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </span>
              </div>
              {error.email && (
                <span className="text-red-600">{error.email}</span>
              )}
            </div>

            <div>
              <div className="relative">
                <input
                  type="password"
                  name="password"
                  className="w-full rounded-lg bg-transparent border-[#25F000] text-white space-x-1 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Enter password"
                  onChange={handleInput}
                />

                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>
              </div>
              {error.password && (
                <span className="text-red-600">{error.password}</span>
              )}
            </div>

            <button
              type="submit"
              className="block w-full rounded-lg bg-green-700 hover:bg-green-900 transition-all px-5 py-3 text-sm font-medium text-white"
            >
              Sign in
            </button>

            <p className="text-center text-sm text-gray-500">
              No account?
              <a className="underline ms-3 text-[#25F000]" href="/signup">
                Sign up
              </a>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
