const Banner = () => {
  return (
    <>
      <div
        id="banner"
        tabindex="-1"
        class="fixed transition-all opacity-100 z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-[#04000ce1] rounded-lg shadow-2xl lg:max-w-7xl left-1/2 top-6 dark:bg-gray-700 dark:border-gray-600"
      >
        <div class="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
          <a
            href="https://flowbite.com/"
            class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600"
          >
            <span class="self-center text-lg font-semibold whitespace-nowrap text-[#0aff58] dark:text-white">
              ScorpionMusic
            </span>
          </a>
          <p class="flex items-center text-sm font-normal text-white dark:text-gray-400">
            To Better Experience, please use your Headphone or Headset.
          </p>
        </div>
      </div>
    </>
  );
};

export default Banner;
